import React, { useContext } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { PageTemplate } from "../components/Templates/InternalTemplate"

const InternalPage = ({ pageContext }) => {
  const pageData = pageContext
  const { title } = pageData

  console.log(pageData)


  return (
    <Layout internal pageData={pageData} customHeaderTitle={title}>
      <SEO title={title} />
      <PageTemplate pageData={pageData} variation="page" />
    </Layout>
  )
}

export default InternalPage
