import React, { useEffect, useContext } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import { PreviewContext } from "context/PreviewContext"

import InternalTemplate from "../../templates/page"
import HomeTemplate from "../../templates/home"
import ContactTemplate from "../../templates/contact"

import { pageData } from "../../fragments/data"
import LoadingBars from "./Loading"

let cleanedPageData = pageData.replace(/WPGraphQL_/g, "")
cleanedPageData = cleanedPageData.replace(/WpPost/g, "Post")
cleanedPageData = cleanedPageData.replace(/WpCaseStudy/g, "CaseStudy")


const GET_POSTS = gql`
  query($postID: ID!) {
    templateData: page(idType: DATABASE_ID, id: $postID) {
      ${cleanedPageData}
    }
    #Static Queries

  }
`

const PreviewPost = ({ id }) => {
  const { setPreviewData } = useContext(PreviewContext)

  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: { postID: id },
  })

  // console.log("Preview DATA", data)

  // set static data to context
  useEffect(() => {
    if (data) {
      setPreviewData(data)
    }
  }, [data])

  return (
    <>
      {loading && <LoadingBars />}

      {data && renderTemplate(data.templateData)}

      {error && <pre>{JSON.stringify(error.networkError, null, 2)}</pre>}
    </>
  )
}

export default PreviewPost

const renderTemplate = (page) => {
  switch (page.pageTemplate) {
    case "home":
      return <HomeTemplate pageContext={page} />
    case "contact":
      return <ContactTemplate pageContext={page} />

    default:
      return <InternalTemplate pageContext={page} />
  }
}
