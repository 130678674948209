const imgObject = `
  sourceUrl
  srcSet
  mediaDetails {
    height
    width
  }
`
// const teaserObject = `
//   title
//   excerpt
//   link
//   featuredImage {
//     ${imgObject}
//   }
// `

const caseStudyData = `
  uri
  title
  content
  caseStudy {
    award
    category
    year
    type
    logo {
      srcSet
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
  }
  publicLibrary {
    pbPdf
    publisherName
    pbYear
    pbType
    pbCity
    pbCountry
    pbLocation
    pbAuthor {
      type
      externalAuthors {
        firstName
        lastName
      }
    }
  }
  categories {
    nodes {
      name
      uri
      slug
      wpChildren {
        nodes {
          uri
          name
          slug
        }
      }
    }
  }
  relatedCaseStudy {
    relatedCaseStudy {
      ... on WpCaseStudy {
        uri
        title
      }
    }
  }
`

const pageData = `
  id
  title
  uri
  link
  content
  pageTemplate
  featuredImage {
    node {
      ${imgObject}
    }
  }

  popularResources {
    popularResources {
      ... on WpPost {
        title
        excerpt
        uri
      }
      ... on WpCaseStudy {
        title
        excerpt
        uri
      }
    }
  }

  
  
 `
const postData = `
  uri
  id
  title
  content
  categories {
    nodes {
      name
      uri
      slug
      wpChildren {
        nodes {
          uri
          name
          slug
        }
      }
    }
  }
  publicLibrary {
    pbPdf
    publisherName
    pbYear
    pbType
    pbCity
    pbCountry
    pbLocation
    pbAuthor {
      type
      externalAuthors {
        firstName
        lastName
      }
    }
  }
  relatedResources {
    relatedResourcesPosts {
      ... on WpPost {
        title
        uri
      }
    }
  }
`

module.exports = {
  pageData,
  postData,
  caseStudyData
}
