import React, { useContext } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { InternalTemplate } from "../components/Templates/InternalTemplate"

const CaseStudy = ({ pageContext }) => {
  const pageData = pageContext
  const { title } = pageData

  return (
    <Layout internal pageData={pageData}>
      <SEO title={title} />
      <InternalTemplate pageData={pageData} caseStudy />
    </Layout>
  )
}

export default CaseStudy
