import React from "react"
import { Router } from "@reach/router"
import PreviewPost from "../components/Preview/PreviewPost"
import PreviewPage from "../components/Preview/PreviewPage"
import PreviewCaseStudy from "../components/Preview/PreviewCasestudy"
import LoadingBars from "../components/Preview/Loading"

const Preview = () => {
  return (
    <>
      <div
        style={{
          background: `#007E8F`,
          color: "#fff",
          top: 0,
          width: "100%",
          textAlign: "center",
          padding: "10px 0",
          position: "sticky",
          zIndex: 9999,
          boxShadow: "1px 1px 18.7px rgba(0,0,0,0.5)",
          fontWeight: 700,
        }}
      >
        Preview Page
      </div>
      <Router>
        <LoadingBars path="/preview" />
        <PreviewPage path="/preview/page/:id/:template/:token" />
        <PreviewPost path="/preview/post/:id/:token" />
        <PreviewCaseStudy path="/preview/casestudy/:id/:token" />
      </Router>
    </>
  )
}

export default Preview
