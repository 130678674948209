import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { InternalTemplate } from "../components/Templates/InternalTemplate"

const InternalPage = ({ pageContext }) => {
  const pageData = pageContext
  const { title, publicLibrary, relatedResources } = pageData

  return (
    <Layout internal pageData={pageData}>
      <SEO title={title} />
      <InternalTemplate
        variation="related"
        pageData={pageData}
        relatedResources={relatedResources?.relatedResourcesPosts}
      />
    </Layout>
  )
}

export default InternalPage
