import React, { useEffect, useContext } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import { PreviewContext } from "context/PreviewContext"
import { caseStudyData } from "../../fragments/data"
import CaseStudyTemplate from "../../templates/caseStudy"

import LoadingBars from "./Loading"



let cleanedPostData = caseStudyData.replace(/WpCaseStudy/g, "CaseStudy")
cleanedPostData = cleanedPostData.replace(/wpChildren/g, "children")


const GET_POSTS = gql`
  query($postID: ID!) {
    templateData: caseStudy(idType: DATABASE_ID, id: $postID) {
      ${cleanedPostData}
    }


  }
`


const PreviewPost = ({ id }) => {
  const { setPreviewData } = useContext(PreviewContext)

  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: { postID: id },
  })

  // console.log("Preview DATA", data)

  console.log(data);

  // set static data to context
  useEffect(() => {
    if (data) {
      setPreviewData(data)
    }
  }, [data])
  return (
    <>
      {loading && <LoadingBars />}

      {data && <CaseStudyTemplate pageContext={data.templateData} />}

      {error && <pre>{JSON.stringify(error.networkError, null, 2)}</pre>}
    </>
  )
}

export default PreviewPost
